.uploaderContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
}

.addExternalUrlContainer {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.externalUrlInput {
  width: 400px;
  padding: 2px;
}

.previouslyUploadedContainer {
  width: 100%;
  border: 0.5px solid rgb(235, 235, 235);
  height: 400px;
  border-radius: 2px;
  overflow-y: auto;
}

.externalDocumentUploadHeader {
  margin-top: 10px;
}

.externalDocumentButton {
  padding: 2px 4px;
}

.uploadDocumentButton {
  background-color: var(--primary-blue);
  color: white;
  border: none;
  cursor: pointer;
  width: 150px;
  height: 30px;
  border-radius: 2px;
}

.uploaderAndTableContainer {
  height: 520px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.isLoadingAtDocs {
  height: 25px;
}

.newBorder {
  border: 0.5px solid rgb(235, 235, 235);
  margin-bottom: 0;
}

.documentsTableItem {
  display: flex;
  justify-content: space-between;
  padding: 2px 5px;
}

.documentsTableItem:hover {
  background-color: rgb(249, 249, 249);
}

.documentsTableItemDelete {
  padding: 2px 6px;
  color: rgb(210, 4, 45);
  cursor: pointer;
  border-radius: 2px;
  font-size: 18px;
}

.documentsTableItemDelete:hover {
  color: rgb(228, 0, 46);
  transition-duration: 250ms;
}

.previouslyUploadedDocumentsTitle {
  padding: 8px 0;
  text-align: center;
  margin-bottom: 5px;
  font-size: 18px;
  color: black;
  font-weight: 700;
  background-color: rgb(236, 236, 236);
}

.ExternalDocumentsUploadheader {
  padding: 4px 0;
  text-align: center;
  font-size: 18px;
  color: black;
  font-weight: 700;
}

.documentsTableItemTitle {
  font-weight: 500;
  text-align: start;
}

.externalUrlAnchor {
  font-size: 14px;
}

.previewAndDeleteContainer {
  width: 250px;
  display: flex;
  justify-content: space-between;
}

.blur {
  filter: blur(8px);
}

.newBorder .file-types {
  display: none;
}

.fileTypesDescription {
  text-align: left;
}
