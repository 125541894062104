body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(246, 247, 249);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.textFieldContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px 0;
}

.checkBoxFieldContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px 0;
}

:root {
  --secondary-blue: rgb(38, 100, 253);
  --primary-blue: rgb(24, 40, 85);
}

.textFieldContainerLong {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px 0;
}

.combine {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.combineStart {
  /* width: 100%; */
  display: flex;
  justify-content: flex-start;
}

.combineStart100 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.combineAround {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.textFieldLong {
  width: 429px;
  height: 28px;
  font-size: 14px;
  padding: 1px 12px;
  border-radius: 4px;
  border: 1px solid rgb(208, 208, 208);
}

.textFieldLongIncident {
  width: 424px;
  height: 28px;
  font-size: 16px;
  padding: 1px 12px;
  border-radius: 4px;
  border: 1px solid rgb(208, 208, 208);
}

label {
  font-size: 14px;
  margin-bottom: 5px;
}

.textFieldLabel {
  font-size: 14px;
  margin-bottom: 5px;
}

/* .textField {
  width: 223px;
  height: 28px;
  font-size: 16px;
  padding: 1px 12px;
  border-radius: 4px;
  border: 1px solid rgb(208, 208, 208);
} */

.textField {
  width: 150px;
  height: 28px;
  font-size: 14px;
  padding: 1px 12px;
  border-radius: 4px;
  border: 1px solid rgb(208, 208, 208);
}

.formRightBorder {
  border-right: 1px solid var(--primary-blue);
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px 0;
}

.centerSingle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.checkbox {
  height: 28px;
  font-size: 16px;
  padding: 1px 12px;
  border-radius: 4px;
  border: 1px solid rgb(208, 208, 208);
}

.standardText {
  font-size: 14px !important;
}

.textfieldSmall {
  width: 120px;
  height: 28px;
  font-size: 14px;
  padding: 1px 12px;
  border-radius: 4px;
  border: 1px solid rgb(208, 208, 208);
}

.textFieldMedium {
  width: 120px;
  height: 28px;
  font-size: 14px;
  padding: 1px 12px;
  border-radius: 4px;
  border: 1px solid rgb(208, 208, 208);
}

.selectField {
  width: 246px;
  height: 28px;
  font-size: 14px;
  padding: 1px 12px;
  border-radius: 4px;
  border: 1px solid rgb(208, 208, 208);
}

.selectFieldSmall {
  width: 146px;
  height: 32px;
  font-size: 14px;
  padding: 1px 12px;
  border-radius: 4px;
  border: 1px solid rgb(208, 208, 208);
}

.selectField:active {
  outline: 0.5px solid rgb(140, 190, 255);
}

.selectField:focus {
  outline: 0.5px solid rgb(140, 190, 255);
}

.textField:active {
  outline: 0.5px solid rgb(140, 190, 255);
}

.textField:focus {
  outline: 0.5px solid var(--secondary-blue);
}
