.Header {
  height: 50px;
  background-color: rgb(17, 28, 59);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerText {
  font-size: 18px;
  color: white;
  margin: 0 15px;
}

.logout:hover {
  cursor: pointer;
  border-bottom: 1px solid white;
}

.headerRightContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  margin-right: 15px;
}

.userGreetingMarginRight {
  margin-right: 10px;
}

.menuOptionsContainer {
  position: absolute;
  top: 50px;
  right: 0;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  border-left: 1px solid white;
  border-bottom: 1px solid white;
  align-items: center;
  width: 150px;
  background-color: white;
  background-color: var(--primary-blue);
  padding: 10px 0;
}

.menuItem {
  padding: 0;
  color: white;
  text-decoration: none;
  padding-bottom: 10px;
}

.menuButtonHeader {
  background-color: var(--primary-blue);
  color: white;
  border: none;
  cursor: pointer;
}

.menuButtonHeader:hover {
  background-color: var(--secondary-blue);
}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  text-decoration: underline;
}
