.App {
  text-align: center;
}

.daCaseworksLogo {
  height: 35px;
  width: 220px;
  z-index: 10;
  background-image: url("./components//views//Common//Images//da-caseworks-logo-white.png");
  background-position: cover;
  background-size: cover;
  margin-left: 10px;
}

.logoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 500px;
  position: absolute;

  bottom: 20px;
  font-size: 20px;
  color: var(--primary-blue);
  left: 20px;
}

.poweredBy {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
