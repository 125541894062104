.witnessesFormContainer {
  height: 50px;
  border-radius: 2px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.centerWitnessActionable {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.witnessListItem {
  display: flex;
  justify-content: space-between;
  padding: 4px 20px;
  align-items: center;
  width: calc(100% - 40px);
  background-color: #f1f1f1;
  border-bottom: 1px solid white;
}

.witnessListEditButton {
  padding: 2px 10px;
  border-radius: 6px;
  border: #33b249;
  background-color: var(--primary-blue);
  color: white;
  letter-spacing: 1px;
  font-size: 14px;
  cursor: pointer;
}

.witnessListNameContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.witnessListFirstName {
  margin-right: 5px;
}

.witnessesListContainer {
  border: 1px solid rgb(236, 236, 236);
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  width: 100%;
  height: 500px;
}

.witnessListContainerForColoring {
  width: 600px;
  height: 430px;
  margin: 0 auto;
  border: 1px solid rgb(236, 236, 236);
  overflow-y: auto;
  border-radius: 2px;
}

.createWitnessButton {
  width: 220px;
  height: 36px;
  max-width: 220px;
  background-color: #6eba60;
  border: none;
  color: white;
  display: block;
  font-size: 18px;
  cursor: pointer;
}

.dialog {
  box-shadow: -5px -5px 10px rgba(70, 70, 70, 0.12),
    5px 5px 10px rgba(70, 70, 70, 0.12);
  background-color: rgb(253, 253, 253);
  border-radius: 6px;
  border: 1px solid rgb(212, 212, 212);
  padding: 0 60px;
  margin: 20px auto;
  width: 650px;
  height: 725px;

  position: fixed;
  top: calc(50% + 30px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.createWitnessButton:hover {
  background-color: #60a853;
}

.witnessListHeader {
  width: 100%;
  margin: 10px auto;
  text-align: center;
  font-size: 20px;
  color: black;
  font-weight: 700;
}
