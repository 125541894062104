.FormWrapper {
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.required {
  color: red;
}

.fullWrapper {
  position: relative;
  width: 650px;
  height: 725px;
  padding: 0 60px;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: rgb(253, 253, 253);
  box-shadow: -10px -10px 15px rgba(70, 70, 70, 0.12),
    10px 10px 15px rgba(70, 70, 70, 0.12);
}

/* .greyTextDefault {
  color: grey;
  background-color: white;
  width: 100%;
  height: 100%;
  font-size: 14px;
} */

select option {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 14pt;
}

select option:first-child {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  color: grey !important;
}

.marginRight {
  margin-right: 60px !important;
}

.marginRightLong {
  margin-right: 90px !important;
}

.marginRightExtraLong {
  margin-right: 148px !important;
}

.navigateSectionsContainer {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 30px;
  width: calc(100% - 120px);
}

.navigateSectionsButton {
  width: 220px;
  height: 36px;
  max-width: 220px;
  background-color: rgb(24, 40, 85);
  border: none;
  color: white;
  display: block;
  font-size: 18px;
  cursor: pointer;
}

.requestAccessButton:hover {
  background-color: rgb(24, 40, 85);
}

.IncidentHeader {
  width: 100%;
  margin: 20px auto;
  text-align: center;
  font-size: 30px;
  color: black;
  font-weight: 700;
}

.CreateIncidentForm {
  height: 220px;
  width: 100%;
}
.incidentCrimeTypeWrapper {
  width: 630px;
  margin-top: 90px;
}

.backNextContainer {
  display: flex;
  justify-content: space-between;
  height: 160px;
  align-items: flex-end;
}

.ATN {
  margin-left: 17px !important;
}
