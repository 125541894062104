.dashboard {
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.caseListContainer {
  width: 100%;
  height: calc(100vh - 150px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.caseListContainer2 {
  width: 100%;
  height: 675px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.caseSearchBar {
  width: 97%;
  height: 18px;
  font-size: 14px;
}

.standardText {
  font-size: 14px !important;
}

.caseSearchBar:focus {
  outline-width: 0;
}

.dashboardNameContainer {
  display: flex;
  justify-content: center;
}

.dashboardDivider {
  margin: 0 6px;
}

.dashboardVictimFirstName {
  margin-right: 4px;
}

.dashboardLineItemCharge {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: var(--primary-blue);
}

.newCaseButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.dashboardLineItemContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.createNewCaseButton {
  padding: 10px 5px;
  border-radius: 6px;
  border: none;
  /* border: #33b249;
  background-color: #33b249; */
  background-color: var(--primary-blue);
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  cursor: pointer;
  width: 200px;
  margin: 30px auto 0 auto;
}

.createNewCaseButton:hover {
  background-color: rgb(30, 50, 105);
}

.caseListWrapper {
  width: 650px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 30px;
  border-radius: 6px;
  box-shadow: -10px -10px 15px rgba(70, 70, 70, 0.12),
    10px 10px 15px rgba(70, 70, 70, 0.12);
}

.caseListHeader {
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
  font-size: 30px;
  color: var(--primary-blue);
  font-weight: 700;
}

.noCasesContainer {
  font-size: 20px;
  width: 100%;
}

.caseList {
  width: 100%;
  height: 500px;
  background-color: #f1f1f1;
  border-radius: 2px;
  overflow-y: auto;
}

.dashboardCaseContainer {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  border-bottom: 0.5px solid rgb(203, 203, 203);
}

.dashboardCaseTitle {
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  color: var(--primary-blue);
}

.dashboardEditButton {
  padding: 2px 8px;
  background-color: #ffbd03;
  color: white !important;
  cursor: pointer;
  border-radius: 2px;
}

.dashboardEditButton:hover {
  background-color: #fcbc0d;
}

.statusAndEditContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboardStatus {
  margin-right: 6px;
  font-size: 14px;
}

.toolTipWrap {
  position: relative;
}

.toolTipWrap .toolTipContent {
  display: none;
  position: absolute;
  bottom: 5%;
  /* left: 0%; */
  right: 100%;
  background-color: #fff;
  border: 1px solid gray;
  padding: 0.5em;
  min-width: 12rem;
}

.toolTipWrap:hover .toolTipContent {
  display: block;
}
