.narrativeFullWrapper {
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.CreateNarrativeForm {
  position: relative;
  width: 650px;
  height: 725px;
  padding: 0 50px;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: rgb(253, 253, 253);
  box-shadow: -10px -10px 15px rgba(70, 70, 70, 0.12),
    10px 10px 15px rgba(70, 70, 70, 0.12);
}

.narrativeHeader {
  width: 100%;
  margin: 20px auto;
  text-align: center;
  font-size: 30px;
  color: black;
  font-weight: 700;
}

.narrativeTextArea {
  width: calc(100% - 10px);
  max-width: calc(100% - 10px);
  min-width: calc(100% - 10px);
  height: 380px;
  padding: 5px 10px;
  color: black;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
