.Landing {
  height: calc(100vh - 50px);
  width: 100vw;
  background-color: rgb(243, 243, 243);
  /* background-image: url("../Common/Images/backgroungImage.png");
  background-size: cover; */
}

.loginContainer {
  height: calc(100vh - 50px);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Seal {
  height: 225px;
  width: 225px;
  background-image: url("../Common/Images/MS-State-Seal.png");
  background-size: cover;
  position: absolute;
  left: 20px;
  top: 65px;
  z-index: 1;
}
