.ForgotPassword {
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ForgotPasswordContainer {
  width: 650px;
  height: 275px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: -10px -10px 15px rgba(70, 70, 70, 0.12),
    10px 10px 15px rgba(70, 70, 70, 0.12);
}

.forgotPasswordInputContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 60%;
}

.forgotPasswordButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.returnToLoginButton {
  margin-top: 10px;
  color: rgb(127, 127, 127);
  cursor: pointer;
  font-size: 16px;
  width: 123px;
}

.returnToLoginButton:hover {
  color: rgb(96, 96, 96);
}

.submitPasswordButton {
  width: 200px;
  margin-top: 20px;
  background-color: rgb(17, 28, 59);
  color: white;
  border: none;
  padding: 8px 0;
  border-radius: 2px;
  cursor: pointer;
}

.submitPasswordButton:hover {
  background-color: rgb(24, 40, 85);
}

.submitPasswordButton:active {
  background-color: rgb(17, 28, 59);
}

.forgotPasswordHeaderContainer {
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* .forgotPasswordHeaderContainer {
  height: 20%;
  width: 100%;
  color: white;
  display: flex;
  background-color: rgb(17, 28, 59);
  justify-content: center;
  align-items: center;
  flex-direction: column;
} */

.forgotPasswordSpinnerContainer {
  width: 100%;
  height: 20%;
}

.forgotPasswordHeader {
  margin-top: 20px;
  font-size: 25px;
}

.backToLoginButton {
  margin-top: 10px;
  color: rgb(127, 127, 127);
  cursor: pointer;
  font-size: 16px;
  width: 99px;
  border-bottom: 1px solid rgb(133, 133, 133);
}

.backToLoginButton:hover {
  border-bottom: 1px solid rgb(96, 96, 96);
  color: rgb(96, 96, 96);
}

label {
  color: rgb(93, 93, 93);
  text-align: start;
}

.forgotPasswordContainer {
  margin-top: 1050px;
}
