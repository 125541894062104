.Nav {
  height: 50px;
  width: 100%;
  background-color: rgb(24, 40, 85);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navItemContainer {
  margin-left: 15px;
  display: flex;
  justify-content: center;
}

.navItem {
  font-size: 15px;
  /* margin: 0 2px; */
  cursor: pointer;
  color: white;
  padding: 4px;
  border-radius: 2px;
  border-bottom: 1px solid rgb(24, 40, 85);
}

.navSpacer {
  color: white;
}

.navItemindividualContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
}

.stepperIcon {
  height: 16px;
  width: 16px;
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: white;
  border: 1px solid white;
  border-radius: 50%;
  /* background-color: rgb(255, 255, 255); */
}

.firstNavItem {
  margin: 0;
}

.progress {
  margin-right: 15px;
  color: white;
}

.activeNavButton {
  /* background-color: rgb(56, 181, 51);
  border-radius: 2px;
  padding-left: 4px; */
  border-bottom: 1px solid rgb(56, 181, 51);
  color: white;
}

.dashboardLinkButton {
  margin-right: 15px;
  color: white;
  cursor: pointer;
}

.dashboardLinkButton:hover {
  border-bottom: 1px solid rgb(56, 181, 51);
}
