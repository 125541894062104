.formContainer {
  margin: 0px auto 0px auto;
  padding: 10px 20px 10px 10px;
  width: 650px;
  height: 500px;
  position: absolute;
  top: 26vh;
}

.inputContainer {
  margin: 40px auto 0px auto;
  background-color: #fafafa;
  height: 425px;
  box-shadow: rgba(0, 0, 0, 0.16) 4px 7px 12px;
  border-radius: 3px;
}

.loginFormWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.forgotPasswordButton {
  margin-left: 69px;
  margin-top: 10px;
  color: rgb(127, 127, 127);
  cursor: pointer;
  font-size: 16px;
  width: 123px;
}

.forgotPasswordButton:hover {
  color: rgb(96, 96, 96);
}

.login-invalid-feedback-container {
  height: 130px;
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.invalid-feedback {
  font-size: 16px;
  color: red;
  height: 20px;
}

.forgotPassword {
  font-size: 11px;
}

.splitLoginAndRequest {
  display: flex;
  justify-content: center;
  margin: 40px auto 0 auto;
  width: 90%;
}

#loginFormHeader {
  margin-top: 20px;
}

.loginPadding {
  margin-left: 6px;
}

#loginFormTitle {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 0rem;
  margin-right: 0.5rem;
  margin-left: 3rem;
  font-size: 22px;
  margin-top: 60px;
}

.loginFormSubTitle {
  margin-left: 50px;
  margin-top: 0px;
  font-weight: 200;
  font-size: 14px;
}

#loginFormBody {
  position: relative;
  width: 220px;
  margin-left: auto;
  margin-right: auto;
}

#loginForm {
  width: 100%;
}

.loginInput {
  border: none;
  background-color: rgba(167, 167, 167, 0.25);
  border-radius: 2px;
  padding: 10px;
  width: 200px;
  margin: 5px 0;
}

.loginInput:focus {
  outline: none;
}

.loginButton {
  width: 220px;
  height: 36px;
  max-width: 220px;
  background-color: rgb(17, 28, 59);
  border: none;
  color: white;
  display: block;
  font-size: 18px;
  cursor: pointer;
  margin: 10px 0 0 0;
}

.requestAccessButton {
  width: 220px;
  height: 36px;
  max-width: 220px;
  background-color: rgb(17, 28, 59);
  border: none;
  color: white;
  display: block;
  font-size: 18px;
  cursor: pointer;
}

.requestAccessButtonWrapper {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.requestAccessButton:hover {
  background-color: rgb(24, 40, 85);
}

.loginButton:hover {
  background-color: rgb(24, 40, 85);
}

.loginButton:active {
  background-color: rgb(17, 28, 59);
}

.loginButton:focus {
  outline: none;
}

.error {
  font-size: 16px;
  font-weight: 500;
  color: red;
  text-align: center;
  bottom: 15px;
  vertical-align: bottom;
}

.loginLoaderContainer {
  display: flex;
  justify-content: center;
}
