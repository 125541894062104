.textFieldContainerOfficer {
  margin: 0 auto !important;
}

.officerEmail {
  margin-left: 46px !important;
}

.dialogOfficer {
  box-shadow: -5px -5px 10px rgba(70, 70, 70, 0.12),
    5px 5px 10px rgba(70, 70, 70, 0.12);
  background-color: rgb(253, 253, 253);
  border-radius: 6px;
  border: 1px solid rgb(212, 212, 212);
  padding: 0 60px;
  margin: 20px auto;
  width: 650px;
  height: 200px;
  position: fixed;
  top: calc(50% - 100px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialogPreview {
  box-shadow: -5px -5px 10px rgba(70, 70, 70, 0.12),
    5px 5px 10px rgba(70, 70, 70, 0.12);
  background-color: rgb(253, 253, 253);
  border-radius: 6px;
  border: 1px solid rgb(212, 212, 212);
  padding: 0 60px;
  margin: 20px auto;
  width: 600px;
  height: 550px;
  position: fixed;
  top: calc(55% - 100px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.viewPreview {
  cursor: pointer;
}

.previewImageContainer {
  width: 40%;
  background-size: contain;
}

.structureImage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
}

.blur {
  filter: blur(8px);
}

.officerPrefillButton {
  width: 70px;
  color: white;
  background-color: var(--primary-blue);
  border: none;
  padding: 4px 10px;
  border-radius: 2px;
  font-size: 16px;
  cursor: pointer;
}

.officerCancelButton {
  width: 70px;
  color: white;
  background-color: var(--primary-blue);
  border: none;
  padding: 4px 10px;
  margin-right: 10px;
  border-radius: 2px;
  font-size: 16px;
  cursor: pointer;
}

.previewCancelButton {
  width: 70px;
  color: white;
  background-color: var(--primary-blue);
  border: none;
  padding: 4px 10px;
  margin-right: 10px;
  border-radius: 2px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}
